import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';

const OOPS_MSG = 'Oops!';
@Injectable()
export class GlobalErrorHandlerService {
  public errorMessage = '';
  constructor(
    private toast: ToastrManager
    ) { }

  public handleError(error: HttpErrorResponse) {
    switch (error && error.status) {
      case 500:
        this.handle500Error(error);
        break;
      case 422:
        this.handle401Or422Error(error);
        break;
        case 403:
          this.handle500Error(error);
          break;
      case 401:
        this.handle401Or422Error(error);
        break;
      default:
        this.handleOtherError(error);
    }
  }

  private handle500Error(error: HttpErrorResponse) {
    const textMsg = 'Something went wrong. Try again in a few minutes';
    this.toast.errorToastr(textMsg, 'Oops!', {showCloseButton: true});
  }

  private handle401Or422Error(error: HttpErrorResponse) {
    if (error.error.error instanceof Array) {
      for (const val of error.error.error) {
        this.toast.errorToastr(val, 'Oops!', {showCloseButton: true});
      }
    } else {
      this.toast.errorToastr(error.error.error, 'Oops!', {showCloseButton: true});
    }
  }

  private handleOtherError(error: HttpErrorResponse) {
    if (error.error.error instanceof Array) {
      for (const val of error.error.error) {
        this.toast.errorToastr(val, 'Oops!', {showCloseButton: true});
      }
    } else {
      this.toast.errorToastr(error.error.error, 'Oops!', {showCloseButton: true});
    }
  }

   // private handle404Error(error: HttpErrorResponse) {
  //   this.createErrorMessage(error);
  //   this.router.navigate(['/404']);
  // }

  // private createErrorMessage(error: HttpErrorResponse) {
  //   this.errorMessage = error.error ? error.error : error.statusText;
  // }

}
