import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-cum-stepper',
  templateUrl: './breadcrumb-cum-stepper.component.html',
  styleUrls: ['./breadcrumb-cum-stepper.component.scss']
})
export class BreadcrumbCumStepperComponent implements OnInit {

  @Input() titleName: string;
  @Input() subTitleName: string;
  @Input() stepper: number;
  constructor() { }

  ngOnInit() {
  }

}
