import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avail-credit-breadcrumb',
  templateUrl: './avail-credit-breadcrumb.component.html',
  styleUrls: ['./avail-credit-breadcrumb.component.scss']
})
export class AvailCreditBreadcrumbComponent implements OnInit {
  @Input() titleName: string;
  @Input() availableCredit: any;
  constructor() { }

  ngOnInit() {
  }

}
