import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SubscriveService } from '../../../../services/subscribe.service';
declare var $: any;
@Component({
  selector: 'app-why-we-choose',
  templateUrl: './why-we-choose.component.html',
  styleUrls: ['./why-we-choose.component.scss']
})
export class WhyWeChooseComponent implements OnInit {


  generatedCaptcha: string;
  subscribeForm: FormGroup;
  captchaValidator = false;
  submitted = false;
  loading = false;

  modalReference: any;

  constructor(
    private formbuilder: FormBuilder,
    public subscribe: SubscriveService,
    public toastr: ToastrManager,
    private modalService: NgbModal,
    private router: Router
  ) {}




  
  

  ngOnInit() {
    this.subscribeForm = this.formbuilder.group({
      emailId: ["", [Validators.required, Validators.email]],
      firstName: ["", [Validators.required, Validators.maxLength(50)]],
      lastName: ["", [Validators.required, Validators.maxLength(50)]],
      captchaText: ["", [Validators.required]],
    });

    this.generateCaptcha();
  }
  
  get f() {
    return this.subscribeForm.controls;
  }

  openSubscribe(newLetterContent) {
    this.submitted = false;
    this.subscribeForm.reset();
    this.modalReference = this.modalService.open(newLetterContent);
  }

  hideLoginModal() {
    this.modalReference.close();
  }

  subscribeFormData() {
    this.submitted = true;
    if (this.subscribeForm.invalid) {
      return;
    }
    if (this.captchaValidator === true) {
      return;
    }
    this.loading = true;
    const obj = {
      first_name: this.subscribeForm.value.firstName,
      last_name: this.subscribeForm.value.lastName,
      email: this.subscribeForm.value.emailId,
    };
    this.subscribe.subscribeUser(obj).subscribe(
      (data: any) => {
        this.toastr.successToastr("User Subscribe successfully.", "Success", {
          showCloseButton: true,
        });
        this.modalReference.close();
        this.loading = false;
        this.submitted = false;
        this.subscribeForm.reset();
        this.generateCaptcha();
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        if (error.error.error instanceof Array) {
          for (const val of error.error.error) {
            this.toastr.errorToastr(val, "Oops!", { showCloseButton: true });
          }
        } else {
          this.toastr.errorToastr(error.error.error, "Oops!", {
            showCloseButton: true,
          });
        }
      }
    );
  }

  // generateing captcha
  generateCaptcha() {
    let result = "";
    const characters =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    for (let i = 0; i < 3; i++) {
      const rnum = Math.floor(Math.random() * charactersLength);
      result += characters.substring(rnum, rnum + 1);
    }
    const randNum = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    this.generatedCaptcha = `${result}${randNum}`;
    this.subscribeForm.patchValue({
      captchaText: "",
    });
  }

  // matching captcha
  captchaValue(data) {
    if (data !== this.generatedCaptcha) {
      this.captchaValidator = true;
    } else {
      this.captchaValidator = false;
    }
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
    $('body,html').animate({scrollTop: 0}, 500);
  }

}
