import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { rootRouterConfig } from './app.routes';
import { HttpClientModule} from '@angular/common/http';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';


import { API_SERVICE_CONFIG, DEFAULT_API_SERVICE_CONFIG } from './config/api-service.config';
import { AuthGuard } from './guard/auth-guard';
import { HomePageBannerComponent } from './main/home-page-banner/home-page-banner.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { IsotopeModule } from 'ngx-isotope';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleModule } from 'angular-user-idle';
import { Myerrorhandelor } from './services';
import { GlobalErrorHandlerService } from './global-error-handler';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomePageBannerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    SlickCarouselModule,
    IsotopeModule,
    NgbModule,
    HttpClientModule,
    UserIdleModule.forRoot({idle: 60 * 30, timeout: 60, ping: 120}),
    ToastrModule.forRoot({
      timeOut: 10000,
      progressBar: false,
      enableHtml: true,
      preventDuplicates: true
    }),
    RouterModule.forRoot(rootRouterConfig, {
      preloadingStrategy: PreloadAllModules, useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })
  ],
  providers: [
    {
    provide: API_SERVICE_CONFIG,
    useValue: DEFAULT_API_SERVICE_CONFIG
    },
    AuthGuard,
    {
      provide: ErrorHandler, useClass: Myerrorhandelor
    },
    GlobalErrorHandlerService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
