import { Injectable } from '@angular/core';

import { BehaviorSubject, Subscriber, Observable } from 'rxjs';


const searchResult = JSON.parse(localStorage.getItem('searchTerms')) || [];
const userType = JSON.parse(localStorage.getItem('userType')) || '';
const defaultCartLength = JSON.parse(localStorage.getItem('cartLength')) || 0;
const payByInvoiceResult = JSON.parse(localStorage.getItem('payByInvoice')) || [
];

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private signInSource = new BehaviorSubject(false);
  signInSourceSourceObservable = this.signInSource.asObservable();

  private cartItemSource = new BehaviorSubject(defaultCartLength);
  cartItemSourceObservable = this.cartItemSource.asObservable();

  private userTypeSource = new BehaviorSubject(userType);
  userTypeSourceObservable = this.userTypeSource.asObservable();

  private searchItemSource = new BehaviorSubject([]);
  searchtemSourceObservable = this.searchItemSource.asObservable();

  public cartItems:  BehaviorSubject<any[]> = new BehaviorSubject([]);
  public observer:  Subscriber<{}>;

  private isloggedInSource = new BehaviorSubject(false);
  loggedInSouceObservable = this.isloggedInSource.asObservable();

  private payinvoiceItemSource = new BehaviorSubject(payByInvoiceResult);
  payinvoiceSourceObservable = this.payinvoiceItemSource.asObservable();

  private linkActiveSource = new BehaviorSubject(null);
  linkActiveSourceSourceObservable = this.linkActiveSource.asObservable();

  constructor() { }

  changeuserType(type: any) {
    this.userTypeSource.next(type);
  }

  changeLoginCondition(data: boolean) {
    this.isloggedInSource.next(data);
  }

  changeCartItemLength(length: any) {
    localStorage.setItem('cartLength', length);
    this.cartItemSource.next(length);
  }

  changeActiveLinkNav(virtual) {
    this.linkActiveSource.next(virtual);
  }

  setSearchItem(data: any[]) {
    if (data) {
      searchResult.push(data);
      localStorage.setItem('searchTerms', JSON.stringify(searchResult));
    }
    return;
  }

  changeSearchItem(data) {
    this.searchItemSource.next(data);
  }

  public getSearchItems(): Observable<any[]> {
    const searchStream = new Observable(observer => {
      observer.next(searchResult);
      observer.complete();
    });
    return <Observable<any[]>>searchStream;
  }


  showDataOnCheckOut(data) {
    this.payinvoiceItemSource.next(data);
  }


  openSignInPopup(data) {
    this.signInSource.next(data);
  }
}
