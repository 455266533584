import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { UserService, SharedService, IntermediateStepService, CartDataService } from '../../../../../services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
// import { AuthService, FacebookLoginProvider, SocialUser } from 'angularx-social-login';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar-one.component.html',
  styleUrls: ['./topbar-one.component.scss']
})
export class TopbarOneComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  submitted = false;
  loading = false;
  url: string;
  // user: SocialUser;
  loggedIn: boolean;
  cartLength = 0;

  forgetPasswordForm: FormGroup;
  submittedForgot = false;
  closeResult: string;
  userDetails: any;


  forgetPasswordFormTwo: FormGroup;
  submittedForgotPassRequest = false;
  selectedQuestion: string;

  modalReference: any;
  show: boolean;


  public isCollapsed = false;
  hideLoginPopup = true;
  routeSubs: Subscription;
  loginSourceSubs: Subscription;
  cartItemSubs: Subscription;

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    // private intermediate: IntermediateStepService,
    public toastr: ToastrManager,
    private modalService: NgbModal,
    private shared: SharedService,
    // private authService: AuthService,
    private cartDataService: CartDataService) {
      this.routeSubs = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
      });

      this.loginSourceSubs = this.shared.loggedInSouceObservable.subscribe((data: boolean) => {
        this.show = data;
      });

      this.cartItemSubs = this.shared.cartItemSourceObservable.subscribe((cartItem: number) => {
        this.cartLength = cartItem;
      });
  }

  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }

  // signOut(): void {
  //   this.authService.signOut();
  //   this.loggedIn = false;
  // }


  ngOnInit() {
    if (JSON.parse(localStorage.getItem('token'))) {
      this.show = true;
      this.getCartItems();
    } else {
      this.show = false;
    }

    this.loginForm = this.formbuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });


    this.forgetPasswordForm = this.formbuilder.group({
      user_identity: ['', [Validators.required]]
    });

    this.forgetPasswordFormTwo = this.formbuilder.group({
      secure_ans: ['', [Validators.required]],
      email: ['', [
        Validators.required,
        Validators.email]
      ]
    });

    // this.authService.authState.subscribe((user) => {
    //   this.user = user;
    //   this.loggedIn = (user != null);
    //   if (this.user !== null) {
    //     const obj = {
    //       social_id: this.user.id,
    //       social_type: this.user.provider.toLowerCase(),
    //       email: this.user.email
    //     };
    //     this.userService.loginWithFacebook(obj).subscribe((data: any) => {
    //       this.userDetails = data.success.user;
    //       localStorage.setItem('intermediateId', JSON.stringify(this.userDetails.user_id));
    //       if (this.userDetails.intermediate_step === 1) {
    //         const userDetailObj = {
    //           full_company_name: this.userDetails.comp_name,
    //           suite_business: this.userDetails.comp_add2,
    //           company_type: this.userDetails.comp_type,
    //           annual_revenue: this.userDetails.revenue,
    //           billing_phone_number: this.userDetails.comp_phone,
    //           line_of_business: this.userDetails.line_of_business,
    //           years_in_business: this.userDetails.years_in_business,
    //           company_address: this.userDetails.comp_add1,
    //           billing_city: this.userDetails.comp_city,
    //           billing_state: this.userDetails.comp_state,
    //           billing_zip_code: this.userDetails.comp_zip,
    //           ein: this.userDetails.ein,
    //           number_of_employees: this.userDetails.number_of_employees,
    //           duns: this.userDetails.duns,
    //           first_name: this.userDetails.first_name,
    //           job_title: this.userDetails.job_title,
    //           shipping_email: this.userDetails.shipping_email,
    //           address: this.userDetails.address,
    //           shipping_state: this.userDetails.state,
    //           zip_code: this.userDetails.zip,
    //           security_question: this.userDetails.secure_ques,
    //           last_name: this.userDetails.last_name,
    //           phone_number: this.userDetails.phonenumber,
    //           city: this.userDetails.city,
    //           dob: this.userDetails.dob,
    //           security_answer: this.userDetails.secure_ans
    //         };
    //         this.intermediate.changeUserDetail(userDetailObj);
    //         this.router.navigate(['/intermediate-register']);
    //       } else {
    //         console.log('details', this.userDetails.token);
    //         localStorage.setItem('token', JSON.stringify(this.userDetails.token));
    //         window.location.href = 'category';
    //         this.shared.changeLoginCondition(true);
    //       }
    //     });
    //   }
    // });
  }

  ngOnDestroy() {
    this.routeSubs.unsubscribe();
    this.loginSourceSubs.unsubscribe();
    this.cartItemSubs.unsubscribe();
  }


  getCartItems() {
    this.cartDataService.getCartItem().subscribe((data: any) => {
      if (data) {
        this.cartLength  = data.success.cartProducts.length;
      }
    });
  }




   get f() { return this.loginForm.controls; }

   loginFormData(event) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService.login(this.loginForm.value.email, this.loginForm.value.password)
    .pipe(first())
    .subscribe(
      (data: any) => {
        this.loading = false;
        localStorage.setItem('token', JSON.stringify(data.user.token));
        window.location.href = 'category';
        this.shared.changeLoginCondition(true);
        this.toastr.successToastr(data.message, 'Success', {showCloseButton: true});
        this.loginForm.reset();
      },
      (error: HttpErrorResponse) => {
          this.toastr.errorToastr(error.error.error, 'Oops!', {showCloseButton: true});
          this.loading = false;
      });
  }

  OpenForgotPassword(content) {
    this.hideLoginPopup = false;
    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.hideLoginPopup = true;
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  get forgotPas() { return this.forgetPasswordForm.controls; }

  forgetPassword(contentrequest) {
    this.submittedForgot = true;
    if (this.forgetPasswordForm.invalid) {
      return;
    }
    this.loading = true;

    this.userService.fogetPasswordStepFirst(this.forgetPasswordForm.value).subscribe((data: any) => {
      this.loading = false;
      this.forgetPasswordForm.reset();
      this.selectedQuestion = data.success.secure_ques;
      this.modalService.dismissAll();
      this.modalReference = this.modalService.open(contentrequest);
      this.modalReference.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    },
    (error: HttpErrorResponse) => {
      this.loading = false;
      this.toastr.errorToastr(error.error.error, 'Oops!', {showCloseButton: true});
    });
  }


  get forgotPasswordRequest() { return this.forgetPasswordFormTwo.controls; }

  forgetPasswordStepTwo() {
    this.submittedForgotPassRequest = true;
    if (this.forgetPasswordFormTwo.invalid) {
      return;
    }
    this.loading = true;
    this.userService.fogetPasswordStepTwo(this.selectedQuestion,
      this.forgetPasswordFormTwo.value.secure_ans, this.forgetPasswordFormTwo.value.email).subscribe((data: any) => {
        this.loading = false;

        this.forgetPasswordFormTwo.reset();
        localStorage.setItem('currentUserDataStepTwo', JSON.stringify(data.success));
        this.modalReference.close();
        this.router.navigate(['/forgot-password-success']);
    }, (error: HttpErrorResponse) => {
      console.log('forgott step 2', error);
      this.loading = false;
      this.toastr.errorToastr(error.error.error, 'Oops!', {showCloseButton: true});
    });
  }


  scroll() {
    const el = document.getElementById('cart-detail');
    if (el) {
      el.scrollIntoView();
    }
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.clear();
    this.shared.changeLoginCondition(false);
    this.emptyCartItem();
    this.router.navigate(['/']);
  }

  emptyCartItem() {
    const cartObj = {
      cart_id: ''
    };
    this.cartDataService.deleteCartItem(cartObj).subscribe((data: any) => {
      this.shared.changeCartItemLength(0);
    }, (error: HttpErrorResponse) => {
    });
  }
}
