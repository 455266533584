import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../config/api-service.config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PersonalAccountService {

  /**
   * apiPrefix: This variable is used to store the API prefix.
   */
  apiPrefix: string;
  headers: any;
  authHeader: any;


  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.headers = config.headers;
    this.authHeader = config.authHeader;
  }

  /**
   * @method userPersonalAccountRegistration
   * @param userData: It get the all info about user(first_name, last_name, password etc.)
   * @description It registered the user.
  */
  userPersonalAccountRegistration = (userData: any) => {
    return this.http.post(`${this.apiPrefix}personal-account`, userData, { headers: this.headers });
  }
}
