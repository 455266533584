import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../config/api-service.config';
import { Observable } from 'rxjs';
import { AddProduct } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CartDataService {

   /**
   * apiPrefix: This variable is used to store the API prefix.
   */
  apiPrefix: string;
  authHeader: any;


  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.authHeader = config.authHeader;
  }


  getCartItem() {
    return this.http.get(`${this.apiPrefix}cart`, { headers: this.authHeader});
  }

  addProductInCart(cartData: AddProduct) {
    return this.http.post(`${this.apiPrefix}add-item`, cartData, { headers: this.authHeader});
  }

  updateCartdataItem(cartData: AddProduct) {
    return this.http.post(`${this.apiPrefix}update-item`, cartData, { headers: this.authHeader});
  }

  deleteCartItem(cart_id: any) {
    return this.http.post(`${this.apiPrefix}delete-item`, cart_id, { headers: this.authHeader});
  }

  trackCode(trackedData: any) {
    return this.http.post(`${this.apiPrefix}track-affiliate`, trackedData, { headers: this.authHeader});
  }
}
