import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { SharedService, CartDataService } from './services';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

import { Subscription, Observable, fromEvent } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { filter, map } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  connectionStatusMessage: string;
  connectionStatus: string;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  readonly googlePlayLink: string;
  readonly appStoreLink: string;
  routeSub: Subscription;
  queryParamRoute: Subscription;
  ipAddress: any;
  routeUrl: string;

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (localStorage.getItem('token') === null) {
      const splitUrl = this.routeUrl.split('/');
      if ((splitUrl.indexOf('category')) > -1) {
        this.shared.changeLoginCondition(false);
        this.router.navigate(['/']);
        return;
      } else if ((splitUrl.indexOf('my-account')) > -1) {
        this.shared.changeLoginCondition(false);
        this.router.navigate(['/']);
      } else if ((splitUrl.indexOf('cart')) > -1) {
        this.shared.changeLoginCondition(false);
        this.router.navigate(['/']);
      } else {
        this.shared.changeLoginCondition(false);
      }
    }
    if (JSON.parse(localStorage.getItem('token'))) {
      this.shared.changeLoginCondition(true);
    }
  }


  constructor(
    private shared: SharedService,
    private router: Router,
    private http: HttpClient,
    private toast: ToastrManager,
    private cartDataService: CartDataService,
    private route: ActivatedRoute,
    private userIdle: UserIdleService,
    public location: Location,
    private titleService: Title,
    public modalService: NgbModal) {

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.routeUrl = event.url;
        if (event.url !== '/cart/checkout') {
          localStorage.removeItem('payByInvoice');
          this.shared.showDataOnCheckOut([]);
        }
        if (event.url !== '/category') {
          $('.modal-backdrop').remove();
        }
        if (event.url !== '/cart/checkout') {
          localStorage.removeItem('payByInvoice');
          this.shared.showDataOnCheckOut([]);
        }

        for (const val of [1]) {
          $('.sign-in-popup').hide();
          $('.global-s').val('');
          this.shared.changeActiveLinkNav(null);
        }
      }
      if (event instanceof NavigationEnd) {
        this.queryParamRoute = this.route.queryParamMap.subscribe((params: any) => {
          const queryParam = this.route.snapshot.queryParamMap.get('track');
          if (queryParam !== null) {
            this.router.navigate(['/register'], { queryParams: { track: queryParam } })
          }
        });
      }
    });

    // this.http.get<{ip: string}>('https://jsonip.com')
    //   .subscribe((data: any) => {
    //     this.ipAddress = data.ip;
    //     this.routeSub = this.route.queryParams
    //     .subscribe((event: any) => {
    //       if (event && event.track) {
    //         const trackCode = event.track;
    //         const trackObj = {
    //           affiliate_code: trackCode,
    //           affiliate_ip: this.ipAddress
    //         };
    //         this.router.navigate(['/']);
    //         this.cartDataService.trackCode(trackObj).subscribe((data: any) => {
    //         }, (error: HttpErrorResponse) => {
    //           if (error.error.error) {
    //             this.toast.errorToastr(error.error.error, 'Oops!', {showCloseButton: true});
    //           }
    //         });
    //       }
    //     });
    // });

  }

  ngOnInit() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      this.toast.successToastr('Internet connected', 'Success', { showCloseButton: true });
      if (this.routeUrl === '/') {
        this.router.navigate(['/']);
      }
      this.location.back();
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      this.toast.errorToastr(this.connectionStatusMessage, 'Oops!', { showCloseButton: true });
      this.router.navigate(['/connection-failure']);
    }));

    // Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      const eventList = [
        'click',
        'mouseover',
        'keydown',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'scroll',
        'keyup'
      ];
      for (const event of eventList) {
        document.body.addEventListener(event, () => this.userIdle.resetTimer());
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      localStorage.clear();
      this.shared.changeLoginCondition(false);
      this.router.navigate(['/']);
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
        , map(() => this.router)
      )
      .subscribe((event) => {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' | ');
        if (title === '') {
          this.titleService.setTitle('COAST TO COAST');
        } else {
          this.titleService.setTitle(title);
        }
      }
      );
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.queryParamRoute.unsubscribe();
    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  onActivate(event) {
    window.scroll(0,0);
       
}
}
