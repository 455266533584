import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ng6-toastr-notifications';

// import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
// Services
import { WINDOW_PROVIDERS } from "./services/windows.service";
import { LandingFixService } from '../shared/services/landing-fix.service';
import { InstagramService } from "./services/instagram.service";
import { ProductsService } from "./services/products.service";
import { WishlistService } from "./services/wishlist.service";
import { CartService } from "./services/cart.service";
import { OrderService } from "./services/order.service";
import { PaginationService } from "./classes/paginate";
// Pipes
import { OrderByPipe } from './pipes/order-by.pipe';
// components
import { HeaderOneComponent } from './header/header-one/header-one.component';


import { TopbarOneComponent } from './header/widgets/topbar/topbar-one/topbar-one.component';
import { TopbarTwoComponent } from './header/widgets/topbar/topbar-two/topbar-two.component';
import { NavbarComponent } from './header/widgets/navbar/navbar.component';
import { SettingsComponent } from './header/widgets/settings/settings.component';
import { LeftMenuComponent } from './header/widgets/left-menu/left-menu.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';

import { InformationComponent } from './footer/widgets/information/information.component';
import { CategoriesComponent } from './footer/widgets/categories/categories.component';
import { WhyWeChooseComponent } from './footer/widgets/why-we-choose/why-we-choose.component';
import { CopyrightComponent } from './footer/widgets/copyright/copyright.component';
import { SocialComponent } from './footer/widgets/social/social.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { ProductCartService } from './services/product-cart.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { MaintenancePageComponent } from './maintenance/maintenance.component';
import { NoConnectionComponent } from './no-connection/no-connection.component';
import { NoProductFoundComponent } from './no-product-found/no-product-found.component';
import { CompanyTermsComponent } from './company-terms/company-terms.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbCumStepperComponent } from './breadcrumb-cum-stepper/breadcrumb-cum-stepper.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { ProductwiseBreadcrumbComponent } from './productwise-breadcrumb/productwise-breadcrumb.component';
import { AvailCreditBreadcrumbComponent } from './avail-credit-breadcrumb/avail-credit-breadcrumb.component';

@NgModule({
  exports: [
    CommonModule,
    TranslateModule,
    HeaderOneComponent,
    FooterOneComponent,
    OrderByPipe,
    LoaderComponent,
    ErrorPageComponent,
    MaintenancePageComponent,
    NoConnectionComponent,
    NoProductFoundComponent,
    CompanyTermsComponent,
    BreadcrumbComponent,
    BreadcrumbCumStepperComponent,
    WhyUsComponent,
    ProductwiseBreadcrumbComponent,
    AvailCreditBreadcrumbComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    // SocialLoginModule,
    ToastrModule.forRoot()
  ],
  declarations: [
    HeaderOneComponent,
    FooterOneComponent,
    OrderByPipe,
    NavbarComponent,
    SettingsComponent,
    LeftMenuComponent,
    TopbarOneComponent,
    TopbarTwoComponent,
    InformationComponent,
    CategoriesComponent,
    WhyWeChooseComponent,
    CopyrightComponent,
    SocialComponent,
    LoaderComponent,
    ErrorPageComponent,
    MaintenancePageComponent,
    NoConnectionComponent,
    NoProductFoundComponent,
    CompanyTermsComponent,
    BreadcrumbComponent,
    BreadcrumbCumStepperComponent,
    WhyUsComponent,
    ProductwiseBreadcrumbComponent,
    AvailCreditBreadcrumbComponent
  ],
  providers: [
    WINDOW_PROVIDERS,
    LandingFixService,
    InstagramService,
    ProductsService,
    WishlistService,
    CartService,
    OrderService,
    PaginationService,
    ProductCartService
  ]
})
export class SharedModule { }
