import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-productwise-breadcrumb',
  templateUrl: './productwise-breadcrumb.component.html',
  styleUrls: ['./productwise-breadcrumb.component.scss']
})
export class ProductwiseBreadcrumbComponent implements OnInit {


  @Input() titleName: string;
  @Input() subTitleName: string;
  @Input() routerUrl: string;

  constructor() { }

  ngOnInit() {
  }

}
