import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { LandingFixService } from '../../services/landing-fix.service';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '../../services/windows.service';
import { CartItem } from '../../classes/cart-item';
import { CartService } from '../../services/cart.service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { UserService, SharedService, CartDataService } from '../../../services';
import { Router } from '@angular/router';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';


declare var $: any;

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderOneComponent implements OnInit, OnDestroy {
  public shoppingCartItems: CartItem[] = [];
  @ViewChild('logincontent', {static: false}) modal: ElementRef;


  allProductData = [];
  searcedhData = [];

  public model: any;
  product = [];
  show: boolean;
  searchData = '';
  loading = false;
  loggedInSubs: Subscription;
  tierType: string | number;

  loginForm: FormGroup;
  submitted = false;
  showPassword = false;
  modalReference: any;

  hideLoginPopup = true;
  closeResult: string;
  // loading = false;

  forgetPasswordForm: FormGroup;
  submittedForgot = false;

  forgetPasswordFormTwo: FormGroup;
  submittedForgotPassRequest = false;
  selectedQuestion: string;
  currentUrl: string;
  checkUserType: string;
  cartItemSubs: any;
  cartLength: number;

  toggleForgotPwdStepOne = false;
  toggleForgotPwdStepTwo = false;
  isSearchOpen = false;

  formatter = (result: string) => result.toString().toUpperCase();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window,
    private fix: LandingFixService,
    private cartService: CartService,
    private userService: UserService,
    private router: Router,
    private shared: SharedService,
    private modalService: NgbModal,
    private formbuilder: FormBuilder,
    public toastr: ToastrManager,
    private cartDataService: CartDataService
  ) {
    this.shared.signInSourceSourceObservable.subscribe((result) => {
      if (result && result === true) {
        setTimeout(() => {
          this.openLoginPopUp(this.modal);
        }, 1000);
      }
    });
    this.tierType = '';
    this.cartService
      .getItems()
      .subscribe(
        (shoppingCartItems) => (this.shoppingCartItems = shoppingCartItems)
      );
    this.loggedInSubs = this.shared.loggedInSouceObservable.subscribe(
      (data: boolean) => {
        this.show = data;
      }
    );
    this.cartItemSubs = this.shared.cartItemSourceObservable.subscribe(
      (cartItem: number) => {
        this.cartLength = cartItem;
      }
    );
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.getAppDownlableProductList();
      this.show = true;
    } else {
      this.show = false;
    }
    this.loginForm = this.formbuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.forgetPasswordForm = this.formbuilder.group({
      user_identity: ['', [Validators.required]],
    });

    this.forgetPasswordFormTwo = this.formbuilder.group({
      secure_ans: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
          ),
        ],
      ],
    });
    $.getScript('assets/js/menu.js');
  }

  ngOnDestroy() {
    this.loggedInSubs.unsubscribe();
  }

  searchProductGlobally(search: string) {

    if (search !== '') {
      this.loading = true;
      const obj = {
        tier_type: this.tierType,
        product_title: search
      };
      this.userService.getSearchableProducts(obj).subscribe((data: any) => {
        this.searcedhData = data.success.productListing;
        this.loading = false;
        setTimeout(() => {
          $('.global-s').val('');
          this.tierType = '';
        }, 200);
        this.shared.setSearchItem(this.searcedhData);
        this.shared.changeSearchItem(this.searcedhData);
        setTimeout(() => {
          this.router.navigate(['/search']);
        }, 100);
      });
    }
  }

  onKeydown(event) {
	console.log(event);
    if (event.key === 'Enter') {
      if ($('.dropdown-menu.show').length === 0) {
        this.searchProductGlobally(event.target.value);
      }
    }
  }

openPopup(){
  this.isSearchOpen = true;
}
closePopup(){
  this.isSearchOpen = false;
}
  openNav() {
    this.fix.addNavFix();
  }

  closeNav() {
    this.fix.removeNavFix();
  }

  /**
   * @description: Search the product based on name
   */
  search = (text$: Observable<any>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === ''
          ? []
          : this.product
            .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 15)
      )
    );
  }

  selectedItem(item) {
    const found = this.allProductData.filter((data: any) => {
      return data.product_name === item.item;
    });
    if (found && found.length) {
      setTimeout(() => {
        $('.global-s').val('');
      }, 200);
      this.router.navigate(['/category', found[0].slug]);
    }
  }

  getAppDownlableProductList() {
    const tierType = '';
    this.userService.getAllTypeProduct(tierType).subscribe((data: any) => {
      this.allProductData = data.success.productListing;
      for (const val of this.allProductData) {
        this.product.push(val.product_name);
      }
    });
  }

  selectCategory(type: any) {
    this.tierType = type;
    if (this.tierType === 1) {
      this.product = this.getProductThroughType(this.tierType);
    } else {
      this.product = this.getProductThroughType(this.tierType);
    }
  }

  getProductThroughType(type: any): any[] {
    this.product = [];
    const typeOfProd = type;
    const found = this.allProductData.filter((data: any) => {
      return data.virtual === typeOfProd;
    });
    for (const val of found) {
      this.product.push(val.product_name);
    }
    return this.product;
  }

  openLoginPopUp(logincontent) {
    this.hideLoginPopup = true;
    this.toggleForgotPwdStepTwo = false;
    this.toggleForgotPwdStepOne = false;
    this.loginForm.reset();
    this.submitted = false;
    this.submittedForgot = false;
    this.forgetPasswordForm.reset();
    this.submittedForgotPassRequest = false;
    // this.modalReference = this.modalService.open(logincontent, { size: 'md' });
    this.modalReference = this.modalService.open(logincontent, { size: 'sm' });
  }


  showSignInPopup() {
    $('.sign-in-popup').show();
  }

  closeMyAcc() {
    $('.sign-in-popup').hide();
  }

  get f() {
    return this.loginForm.controls;
  }

  loginFormData() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.submitted = false;
          localStorage.setItem('currentUser', JSON.stringify(data.user));
          localStorage.setItem('token', JSON.stringify(data.user.token));
          localStorage.setItem('userType', JSON.stringify(data.user.user_type));
          window.location.href = 'category';
          this.shared.changeLoginCondition(true);
          this.toastr.successToastr(data.message, 'Success');
          this.loginForm.reset();
        },
        (error: HttpErrorResponse) => {
          this.toastr.errorToastr(error.error.error, 'Oops!', {
            showCloseButton: true,
          });
          this.loading = false;
        }
      );
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  openForgotPassword() {
    this.hideLoginPopup = false;
    this.toggleForgotPwdStepOne = true;
    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  hideLoginModal() {
    this.modalReference.close();
    this.hideLoginPopup = true;
    this.toggleForgotPwdStepOne = false;
    this.toggleForgotPwdStepTwo = false;
    
  }


  get forgotPas() {
    return this.forgetPasswordForm.controls;
  }

  forgetPassword() {
    this.submittedForgot = true;
    if (this.forgetPasswordForm.invalid) {
      return;
    }
    this.loading = true;

    this.userService
      .fogetPasswordStepFirst(this.forgetPasswordForm.value.user_identity)
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.forgetPasswordForm.reset();
          this.selectedQuestion = data.success.secure_ques;
          this.submittedForgot = false;
          this.toggleForgotPwdStepTwo = true;
          this.toggleForgotPwdStepOne = false;
          localStorage.setItem(
            'currentUserDataStepTwo',
            JSON.stringify(data.success.email)
          );
          this.modalReference.result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
        },
        (error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            if (!navigator.onLine) {
              this.toastr.errorToastr(
                'Connection Lost! You are not connected to internet.',
                'Oops!',
                {
                  position: 'top-right',
                  showCloseButton: true,
                }
              );
              return;
            }
          }
          this.loading = false;
          this.toggleForgotPwdStepTwo = false;
          this.toastr.errorToastr(error.error.error, 'Oops!', {
            position: 'top-right',
            showCloseButton: true,
          });
        }
      );
  }

  get forgotPasswordRequest() {
    return this.forgetPasswordFormTwo.controls;
  }

  forgetPasswordStepTwo() {
    this.submittedForgotPassRequest = true;
    if (this.forgetPasswordFormTwo.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .fogetPasswordStepTwo(
        this.selectedQuestion,
        this.forgetPasswordFormTwo.value.secure_ans,
        this.forgetPasswordFormTwo.value.email
      )
      .subscribe(
        (data: any) => {
          this.loading = false;

          this.forgetPasswordFormTwo.reset();
          this.submittedForgotPassRequest = false;
          
          this.router.navigate(['/forgot-password-success']);
          this.modalReference.close();
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.toastr.errorToastr(error.error.error, 'Oops!', {
            showCloseButton: true,
          });
        }
      );
  }

  navigateTo() {
    this.router.navigate(['/register']);
  }


  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.clear();
    this.shared.changeLoginCondition(false);
    this.emptyCartItem();
    this.router.navigate(['/']);
  }

  emptyCartItem() {
    const cartObj = {
      cart_id: ''
    };
    this.cartDataService.deleteCartItem(cartObj).subscribe((data: any) => {
      this.shared.changeCartItemLength(0);
    }, (error: HttpErrorResponse) => {
    });
  }
  
}
