import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()

export class Myerrorhandelor implements ErrorHandler {
  constructor() {
  }

  /**
   * @param error
   * @author Anku keshri
   * @description : Reload the page if there is any error of http client.
   */
  handleError(error) {
    // window.location.reload();
  }
  // handleError(error: Error | HttpErrorResponse) {
  //   console.log('error', error)
  //   if (error instanceof HttpErrorResponse) {
  //      if (!navigator.onLine) {
  //        console.log('call offline');
  //      } else {
  //      }
  //   } else {
  //   }
  // }
}

